import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { Button, Grid, Typography } from '@material-ui/core';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPray, faUserCircle } from '@fortawesome/pro-solid-svg-icons';
// import { faBooks, faUpload } from '@fortawesome/pro-solid-svg-icons';
import appTheme from '../../AppTheme';

// const buttonIconSize = '2x';

// MUI Overrides
const theme = createTheme({
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 0
            },
            containedPrimary: {
                backgroundColor: "black",
                "&:hover": {
                    backgroundColor: appTheme.palette.etc.hover,
                }
            },
            containedSecondary: {
                backgroundColor: appTheme.palette.etc.main,
                "&:hover": {
                    backgroundColor: appTheme.palette.etc.hover
                }
            },
            label: {
                justifyContent: "flex-start"
            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
    sHeader: {
        margin: "20px 0px 0px 0px",
        fontSize: theme.palette.account.headers
    },
    button: {
        borderRadius: 0,
    }
}));

export default function ProfileNavigation({ section, setSection }) {
    const classes = useStyles();
    return (
        <Grid container justifyContent='center'>
            {/* User Info */}
            <Grid container item direction='column'>
                <Typography variant='h6' className={classes.sHeader}>
                    <strong>User Information</strong>
                </Typography>
                <MuiThemeProvider theme={theme}>
                    <Button 
                        className={classes.button}
                        variant="contained" 
                        color={section === "userInfo" ? "secondary":"primary"}
                        disableElevation
                        // startIcon={<FontAwesomeIcon size={buttonIconSize} icon={faUserCircle} />}
                        onClick={() => setSection('userInfo')}
                    >
                        Profile
                    </Button>
                </MuiThemeProvider>
            </Grid>

            {/* User Submissions */}
            <Grid container item direction='column'>
                <Typography variant='h6' className={classes.sHeader}>
                    <strong>Your Submissions</strong>
                </Typography>
                <MuiThemeProvider theme={theme}>
                    <Button 
                        className={classes.button}
                        variant="contained" 
                        color={section === "userRequests" ? "secondary":"primary"}
                        disableElevation
                        // startIcon={<FontAwesomeIcon size={buttonIconSize} icon={faPray} />}
                        onClick={() => setSection('userRequests')}
                    >
                        Requests
                    </Button>
                </MuiThemeProvider>
               {/*  <Button 
                    className={classes.button}
                    variant="contained" 
                    color="primary" 
                    style={{marginTop: 5}}
                    disableElevation
                    startIcon={<FontAwesomeIcon size={buttonIconSize} icon={faUpload} />}
                    onClick={() => setSection('userUploads')}
                >
                    Uploads
                </Button> */}
            </Grid>

            {/* User Librarires
            <Grid container item xs direction='column' alignContent='center'>
                <Typography variant='h6' className={classes.sHeader}>
                    <strong>Libraries</strong>
                </Typography>
                <Button 
                    className={classes.button}
                    variant="contained" 
                    color="primary" 
                    disableElevation
                    startIcon={<FontAwesomeIcon size={buttonIconSize} icon={faBooks} />}
                    onClick={() => setSection('userLibs')}
                >
                    Libraries
                </Button>
            </Grid> */}
        </Grid>
    )
}
