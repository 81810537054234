import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { LRAuthProvider } from 'loginradius-react';
import { UserContextProvider } from './contexts/UserContext';

// Render the application to the DOM
ReactDOM.render(
    // <React.StrictMode>
        <LRAuthProvider
            appName="etcconnect"
            apiKey="a5c428d2-de6f-47b5-ba7e-5f7534c54d80"
            redirectUri={window.location.origin}
            customDomain="login.etcconnect.com"
        >
            <UserContextProvider>
                <App />
            </UserContextProvider>
        </LRAuthProvider>,
    // </React.StrictMode>,
    document.getElementById("root")
);
serviceWorker.unregister();